@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
* {
  font-family: "Sora", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #030303;
}

h2 {
  color: #15cdfc;
}

h3 {
  color: #818384;
}

.Home {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  font-size: 25px;
  text-align: center;
}

.Home h1 {
  margin-top: 25vh;
  margin-bottom: 2.5vh;
  color: #15cdfc;
  padding-left: 7px;
  padding-right: 7px;

  letter-spacing: 1px;
}

.Home p {
  padding: 0;
  margin: 0;
  color: #d7dadc;
}

.Upcoming {
  margin-top: 150px;
  font-size: 15px;
  color: #818384;
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
}

@-webkit-keyframes text {
  0% {
    color: black;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
  30% {
    letter-spacing: 25px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
  85% {
    letter-spacing: 1px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
}

@keyframes text {
  0% {
    color: black;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
  30% {
    letter-spacing: 25px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
  85% {
    letter-spacing: 1px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }
}

.Home h4 {
  color: #15cdfc;
}

h4::before {
  content: "";
  -webkit-animation: animate infinite 5.5s;
          animation: animate infinite 5.5s;
}
@-webkit-keyframes animate {
  0% {
    content: "Cybersecurity";
  }
  33% {
    content: "Hacking";
  }
  66% {
    content: "BugBounty";
  }
  100% {
    content: "OSCP";
  }
}
@keyframes animate {
  0% {
    content: "Cybersecurity";
  }
  33% {
    content: "Hacking";
  }
  66% {
    content: "BugBounty";
  }
  100% {
    content: "OSCP";
  }
}

/* Link Design */

#links {
  max-width: 675px;
  width: auto;
  display: block;
  margin: 27px auto;
}

li {
  display: block;
  background-color: #131316; /*#13191f;*/ /*#151A21;*/
  color: #141416;
  text-align: center;
  margin-bottom: 5px;
  padding: 17px;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.25s cubic-bezier(0.1, 0.69, 0.29, 0.99);
  border: solid black 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
}

li:hover {
  background-color: #080808;
  color: #e6e6e6;
}

li:hover a {
  color: #15cdfc;
}

/* Hacking Page */

.Hacking {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

a {
  color: #d7dadc;
  text-decoration: none;
}

/* OSCP Page */

.OSCP {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

/* BugBounty Page */

.BugBounty {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

/* Creators Page */

.Creators {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

/* Writeups Page */

.Writeups {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

/* mobile Pentesting Page */

.mobilePentesting {
  justify-content: center;
  align-items: center;
  color: #d7dadc;
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
}

